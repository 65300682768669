/* eslint-disable jsx-a11y/alt-text */
import gurt from "./img/gurt.png";
import mdc from "./img/mdc.png";
import ica from "./img/ica.png";
import siggis from "./img/siggis.png";
import headshot from "./img/headshot.png";

import bnc from "./img/bnc.png";
import bb from "./img/b-b.png";
import bi from "./img/b-i.png";
import bp from "./img/b-p.png";

import nyu from "./img/nyu.png";
import crowd from "./img/crowd.png";
import natu from "./img/natu.png";
import upc from "./img/upc.png";

import main from "./img/main.png";
import Hamburger from "hamburger-react";

import logos from "./img/logos.png";

import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

function Menu(props) {
  const data = [
    "guiding principles",
    "brand mission",
    "recent work",
    "what we do",
    "past work",
    "meet our founder",
  ];

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vw-scale",
      `${Math.min((window.innerWidth - 128) / 672, 1.25)}`
    );

    window.addEventListener("resize", () => {
      if (window.innerWidth > 800) {
        document.documentElement.style.setProperty(
          "--vw-scale",
          `${Math.min((window.innerWidth - 128) / 672, 1.25)}`
        );
      }
    });
  }, []);

  return (
    <div
      className={
        "fixed top-0 z-20 h-screen duration-[400] text-deep-g text-3xl font-bold tracking-tight child:cursor-pointer transition-opacity w-screen flex-col bg-white flex justify-center align-middle gap-4 " +
        (props.isOpen ? "opacity-100" : "opacity-0 pointer-events-none")
      }
    >
      {data.map((item) => (
        <div className="child:w-fit mx-auto">
          <a
            href={"#" + item}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {item}
          </a>
        </div>
      ))}
    </div>
  );
}

function SlideView({ slide, setSlide }) {


  return (
    <div
      className={
        "fixed top-0 z-30 h-screen p-10 duration-[400] text-deep-g text-3xl font-bold tracking-tight child:cursor-pointer transition-opacity w-screen flex-col grad flex justify-center align-middle gap-4 " +
        (slide ? "opacity-100" : "opacity-0 pointer-events-none")
      }
    >
      <div
        className="fixed top-5 right-8"
        onClick={() => {
          setSlide(false);
        }}
      >
        X
      </div>
      {slide && (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={400}
          navigation={true}
          pagination={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper container max-w-[850px] shadow"
        >
          {slide.video && (
            <SwiperSlide>
              <video
                src={`/img/${slide.id}/1.mp4`}
                className="w-full h-full "
                controls
                autoPlay
              />
            </SwiperSlide>
          )}
          {[...Array(slide.num).keys()].map((x) => (
            <SwiperSlide>
              <img
                src={`/img/${slide.id}/${x + 1}.jpg`}
                className="w-full h-full "
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}

function Nav(props) {
  return (
    <div className="fixed z-30 top-0 bg-white shadow !rounded-t-none round w-screen flex justify-between align-middle px-10">
      <img src={main} alt="main logo" className="h-20 w-auto py-4" />
      <div className="h-fit my-auto">
        <Hamburger toggled={props.isOpen} toggle={props.setOpen} />
      </div>
    </div>
  );
}

function App() {
  let [princ, setPrinc] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [slide, setSlide] = useState(null);

  return (
    <div className="overflow-x-clip">
      <Nav isOpen={isOpen} setOpen={setOpen} />
      <Menu isOpen={isOpen} setOpen={setOpen} />
      <SlideView slide={slide} setSlide={setSlide} />
      <div className="main pb-14 ">
        <div className="round  max-w-2xl bg-white mx-auto p-10 pt-28 text-center text-light-g w-full md:p-16 md:shadow-xl px-6 md:px-20 child:py-10 4 md:mt-28">
          <div className="pt-0">
            <div className="">
              <div className="!-z-50  ">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  speed={400}
                  navigation={true}
                  modules={[Autoplay]}
                  className="mySwiper absolute"
                >
                  <SwiperSlide>
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="mx-auto w-full round aspect-video  object-cover shadow "
                    >
                      <source src={"videos/siggs.webm"} type="video/webm" />
                      <source src={"videos/siggs.mp4"} type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide>
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="mx-auto w-full round aspect-video  object-cover shadow "
                    >
                      <source src={"videos/beach.webm"} type="video/webm" />
                      <source src={"videos/beach.mp4"} type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide>
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="mx-auto w-full round aspect-video  object-cover shadow "
                    >
                      <source src={"videos/weather.webm"} type="video/webm" />
                      <source src={"videos/weather.mp4"} type="video/mp4" />
                    </video>
                  </SwiperSlide>
                </Swiper>
                <div className="w-full pt-3 flex justify-center align-middle">
                  <img className="z-10 h-28 " src={main} />
                </div>
              </div>
            </div>

            <div className="">
              <div className="text-deep-g">
                <h1 className="font-bold text-6xl tracking-tighter">
                  buckstarter
                </h1>
                <p className="text-xl -mt-1">helps get brands moving</p>
              </div>
              <p className="px-8 max-w-xl mx-auto text-center font-bold pt-4">
                is a full-service branding studio that gives businesses
                strong(er) points-of-view and greater market presence.
              </p>
            </div>
          </div>
          <div className="">
            <h3
              className="text-left text-3xl font-bold tracking-tight"
              id="guiding principles"
            >
              guiding principles
            </h3>
            <div
              className="flex justify-between pt-8 text-xl gap-4 pb-8
                    child:border-2 child:border-light-g child:rounded-md child:flex-1 child:shadow child:cursor-pointer"
            >
              <p
                className={princ === 0 && "bg-light-g text-white shadow-none"}
                onClick={() => {
                  setPrinc(0);
                }}
              >
                simplicity
              </p>
              <p
                className={princ === 1 && "bg-light-g text-white shadow-none"}
                onClick={() => {
                  setPrinc(1);
                }}
              >
                clarity
              </p>
              <p
                className={princ === 2 && "bg-light-g text-white shadow-none"}
                onClick={() => {
                  setPrinc(2);
                }}
              >
                allure
              </p>
            </div>
            <div className="border-light-g border-2 round p-10 text-left shadow font-bold">
              <div className={princ === 0 ? "" : "hidden"}>
                Simplicity is defining a brand’s purpose or positioning simply
                and elegantly. This is an art of careful extraction. It requires
                discipline and courage to omit the extraneous and to dedicate
                focus on what matters most. This is the heart of great branding.
              </div>
              <div className={princ === 1 ? "" : "hidden"}>
                Clarity is a compass in today’s distracted world. A brand must
                take a point-of-view and be absolutely clear of what it stands
                for — and what it stands against — if it hopes to break-through
                and get noticed.
              </div>
              <div className={princ === 2 ? "" : "hidden"}>
                The magnetic power to attract others to what you are doing or
                saying. It can be manufactured with words, strategies and color
                palettes.  It is the art of
                what you don’t say as much as what you do say.
              </div>
            </div>
          </div>
          <div>
            <h3
              className="text-left text-3xl pb-7 font-bold tracking-tight"
              id="brand mission"
            >
              brand mission
            </h3>
            <iframe width={560} height={315} src="https://www.youtube-nocookie.com/embed/4X21BHU5CnA?controls=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />

          </div>
          <div className="">
            <h3
              className="text-left text-3xl font-bold tracking-tight"
              id="recent work"
            >
              recent work
            </h3>
            <div
              className="grid justify-between pt-8 gap-4 grid-cols-2
                  child:rounded-xl  child:shadow child:object-cover child:!h-36  child:w-full"
            >
              <img
                src={bnc}
                onClick={() => {
                  setSlide({ id: "bnc", num: 7 });
                }}
              />
              <img
                src={ica}
                onClick={() => {
                  setSlide({ id: "ica", num: 0, video: true });
                }}
              />
              <img
                src={mdc}
                onClick={() => {
                  setSlide({ id: "mdc", num: 0, video: true });
                }}
              />
              <img
                src={siggis}
                onClick={() => {
                  setSlide({ id: "siggis", num: 0, video: true });
                }}
              />
              <img
                src={nyu}
                onClick={() => {
                  setSlide({ id: "nyu", num: 8 });
                }}
              />
              <img
                src={crowd}
                onClick={() => {
                  setSlide({ id: "crowd", num: 3, video: true });
                }}
              />
              <img
                src={natu}
                onClick={() => {
                  setSlide({ id: "natu", num: 9 });
                }}
              />
              <img
                src={upc}
                onClick={() => {
                  setSlide({ id: "upc", num: 3 });
                }}
              />
            </div>
          </div>
          <div className="">
            <h3
              className="text-left text-3xl font-bold tracking-tight pb-8"
              id="what we do"
            >
              we focus on three sectors
            </h3>
            <div className=" flex justify-between child:w-1/3 child:child:w-1/2 gap-4 ">
              <div>
                <img src={bi} />
              </div>
              <div>
                <img src={bb} />
              </div>
              <div>
                <img src={bp} />
              </div>
            </div>
            <div
              className="flex justify-between pt-5 text-xl gap-4 pb-4 
                    child:border-2 child:rounded-md child:flex-1 child:shadow"
            >
              <p className="text-[#36ACFE] border-[#36ACFE]">innovation</p>
              <p className="text-[#FA5145] border-[#FA5145]">branding</p>
              <p className="border-light-g">production</p>
            </div>
            <div className="flex text-left child:flex-1 gap-4 md:gap-12">
              <div>
                strategy
                <br />
                design <br />
                architecture <br />
                purpose
            
              </div>
              <div>
                narrative
                <br />
                advertising <br />
                content
              </div>
              <div>
                content
                <br />
                digital <br />
                video <br />
                print
              </div>
            </div>
          </div>
          <div className="">
            <h3
              className="text-left text-3xl font-bold tracking-tight pb-6"
              id="past work"
            >
              past work
            </h3>
            <img src={logos} />
          </div>
          <div className="!pb-0">
            <h3
              className="text-left text-3xl font-bold tracking-tight pb-8"
              id="meet our founder"
            >
              meet our founder
            </h3>
            <div className="flex justify-center pb-10 gap-8">
              <img
                className="rounded-full h-32"
                src={headshot}
                alt="photo of Scott Buckley"
              />
              <div className="my-auto text-left text-2xl">
                <p className="pb-3">Scott Buckley</p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/scott-buckley-b55351b/"
                >
                  <svg
                    height="30"
                    viewBox="0 0 24 24"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-[#36ACFE] hover:opacity-80 transition-opacity"
                  >
                    <path d="m19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476z" />
                  </svg>
                </a>
              </div>
            </div>
            <p className="px-8  mx-auto text-center font-bold ">
              Buckstarter is a 'hive' of seasoned advertising pros led by Scott
              Buckley. Scott has years of global marketing experience, having
              worked for Saatchi & Saatchi as well as smaller privately owned
              companies. Check out his Linkedin or email us and let's get
              started!
            </p>
            <div className="pt-8">
              <a href="mailto:scott@buckstarter.com">
                <div className="bg-[#FA5145] w-fit mx-auto text-white py-2 px-6 text-xl rounded-lg">
                  or email us
                </div>
              </a>
            </div>
            <p className="px-8 pt-8  mx-auto text-left font-bold text-center">
              contact us for a conversation about your business
            </p>
          </div>
          <p className=" text-sm font-bold pb-0 mx-auto">
            Design by <a target="_blank" href="https://taw.vision">Studio TAW</a> <br /> © Buckstarter, LLC. {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
